import { useState, useEffect, useRef } from "react"; // Include useRef
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Include your custom CSS

// web3 wallet


import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAccount } from 'wagmi'
import { useSignMessage } from 'wagmi'




// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '91d75e3e006c87b17d3ea1a41f4a6a93'

// 2. Create wagmiConfig
const metadata = {
  name: 'electric.film Gif-Gen',
  description: 'AI Gif Generator',
  url: 'https://gif-gen.rtgen.ai', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, polygon]
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  //...wagmiOptions // Optional - Override createConfig parameters
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
})



export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}


/// 

function App() {
  //web3 stuff
  const { address, isConnected } = useAccount();
  const [hasAccess, setHasAccess] = useState(false);
  const [isCheckingAccess, setIsCheckingAccess] = useState(false);

  const checkTokenGating = async (walletAddress) => {
    const API_URL = 'https://api.collab.land/access-control/check-roles';
    const API_KEY = process.env.REACT_APP_COLLABLAND_API_KEY;

    const payload = {
      account: walletAddress,
      rules: [
        {
          chainId: 1, // Example: Ethereum Mainnet
          contractAddress: '0x222aA00fEc712f18a98a332204a1508FA996Ee46', // Replace with your token's contract address
          minToken: '1', // Minimum number of tokens required
          type: 'ERC721', // Token type (ERC20, ERC721, etc.)
          roleId: 'NFT_holder', // Define a role ID for your gating rule
        },
        {
          chainId: 137, // Chain ID for Polygon
          contractAddress: '0x22D4DFE6dA0D661F48BD7c8a9224fF1c866Fe0E2', // Replace with your token's contract address on Polygon
          minToken: '1000', // Minimum amount of the token the user should hold
          type: 'ERC20', // Assuming it's an ERC20 token
          roleId: 'Polygon_token_holder', // A unique ID for this gating rule
        }
      ],
    };

    try {
      const response = await axios.post(API_URL, payload, {
        headers: {
          'X-API-KEY': API_KEY,
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error checking token gating:', error);
      return null;
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      setIsCheckingAccess(true); // Use a specific loading state for access checking
      checkTokenGating(address)
        .then((result) => {
          const hasRoleAccess = result.roles.some(role => role.granted);
          setHasAccess(hasRoleAccess);
        })
        .catch((error) => {
          console.error('Error checking access:', error);
          setHasAccess(false); // Consider access denied on error
        })
        .finally(() => {
          setIsCheckingAccess(false); // Set the specific loading state to false when done
        });
    } else {
      setHasAccess(false); // No access if not connected
      setIsCheckingAccess(false); // Ensure the loading state is false when not connected
    }
  }, [isConnected, address]); // Include address in the dependency array


  // video

  const [videoUrl, setVideoUrl] = useState("");
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [presignedUploadUrl, setPresignedUploadUrl] = useState('');
  const [presignedDownloadUrl, setPresignedDownloadUrl] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);

  const [estimateTime, setEstimateTime] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);


  // Handle file selection

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Reset the upload status and video preview
      setUploadStatus('');
      setVideoPreviewUrl(null);
      // Check for file size to not exceed 20MB (20 * 1024 * 1024 bytes)
      if (file.size > 20 * 1024 * 1024) {
        setUploadStatus('File size exceeds 20MB. Please select a smaller file.');
        return;
      }

      // Check for file type to be either mp4 or mov with H264 encoding
      if (!(file.type === 'video/mp4' || (file.type === 'video/quicktime'))) {
        setUploadStatus('Invalid file format. Only MP4 and MOV (H264) are allowed.');
        return;
      }

      // Retrieve the duration of the video to ensure it's not longer than 30 seconds
      const videoDuration = await getVideoDuration(file);
      if (videoDuration > 30) {
        setUploadStatus('Video length exceeds 30 seconds. Please select a shorter video.');
        return;
      }

      setVideoDuration(videoDuration); // Store the duration in state
      setSelectedVideo(file);
      await fetchPresignedUrl(file);  // This should proceed only if all checks pass
    }
  };


  // Utility function to get the duration of the video
  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      }
      video.src = URL.createObjectURL(file);
    });
  };



  const fetchPresignedUrl = async (file) => {
    try {
      const response = await axios.get(process.env.REACT_APP_PRESIGNED_URL);
      console.log('Response for presigned URL:', response.data);
      if (response.data.uploadURL && response.data.downloadURL) {
        console.log('Presigned URL fetched:', response.data.uploadURL);
        setPresignedUploadUrl(response.data.uploadURL);  // Set the URL from the correct property
        console.log('Presigned DownloadURL fetched:', response.data.downloadURL);
        setPresignedDownloadUrl(response.data.downloadURL);  // Set the URL from the correct property
      } else {
        console.error('Presigned URL not found in response');
        setUploadStatus('Failed to get upload URL');
      }
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
      setUploadStatus('Failed to get upload URL');
    }
  };


  const uploadVideo = async () => {
    if (!presignedUploadUrl || !selectedVideo) {
      console.log('Missing URL or file:', { presignedUploadUrl, selectedVideo });
      setUploadStatus('Missing URL or file for upload');
      return;
    }

    try {
      console.log('Attempting to upload video with URL:', presignedUploadUrl);
      const response = await axios.put(presignedUploadUrl, selectedVideo, {
        headers: {
          'Content-Type': 'video/mp4'  // Ensure this matches your presigned URL's required content type
        }
      });

      if (response.status === 200) {
        setUploadStatus('Upload successful');
        //setVideoUrl(presignedDownloadUrl);  // Store the URL without query parameters
        console.log('URL of uploaded video:', presignedDownloadUrl);
        setVideoPreviewUrl(presignedDownloadUrl); // Set video URL for preview
      } else {
        throw new Error('Failed to upload video');
      }
    } catch (error) {
      console.error('Error uploading video:', error);
      setUploadStatus('Upload failed');
    }
  };



  // Add this state for video preview



  //

  const [imageBlob, setImageBlob] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isGeneratingImage = useRef(false);
  const [jobId, setJobId] = useState(null); // Add state to store Job ID
  const [apiStatus, setApiStatus] = useState(null);  // Add this line near your other useState declarations
  const myAPIKey = process.env.REACT_APP_MY_API_KEY;
  const [jobIds, setJobIds] = useState([]); // Changed to an array to store multiple job IDs
  const [generatedImages, setGeneratedImages] = useState([]); // Store generated image URLs
  // State for current generation images and gallery images
  const [currentImages, setCurrentImages] = useState([]);
  const [failedJobs, setFailedJobs] = useState({});




  const [galleryImages, setGalleryImages] = useState([]);
  const [jobIndexMap, setJobIndexMap] = useState({});
  const placeholderImage = `${process.env.PUBLIC_URL}/placeholder.png`; // Update with actual placeholder image path
  const [newJobIndexMap, setNewJobIndexMap] = useState({});
  const [loraSelection, setLoraSelection] = useState(" "); // Default Lora

  const [backendType, setBackendType] = useState('comfy'); // 'comfy' for ComfyUI
  const toggleBackendType = () => {
    setBackendType(backendType === 'comfy' ? 'comfy' : 'automatic');
  };

  const [useRandomSeed, setUseRandomSeed] = useState(true);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [presetOptions, setPresetOptions] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState('');

  const apiURL = process.env.REACT_APP_API_URL || 'http://192.168.1.123:5000';

  const [progressPercentage, setProgressPercentage] = useState(0);
  const progressIntervalRef = useRef(null);




  const gifPreset = {

    "1": {
      "inputs": {
        "ckpt_name": "cardosAnime_v20.safetensors",
        "beta_schedule": "sqrt_linear (AnimateDiff)",
        "use_custom_scale_factor": false,
        "scale_factor": 0.18215
      },
      "class_type": "CheckpointLoaderSimpleWithNoiseSelect",
      "_meta": {
        "title": "Load Checkpoint w/ Noise Select 🎭🅐🅓"
      }
    },
    "2": {
      "inputs": {
        "vae_name": "sdxl_vae.safetensors"
      },
      "class_type": "VAELoader",
      "_meta": {
        "title": "Load VAE"
      }
    },
    "6": {
      "inputs": {
        "text": "(bad quality, worst quality:1.2)",
        "clip": [
          "1",
          1
        ]
      },
      "class_type": "CLIPTextEncode",
      "_meta": {
        "title": "CLIP Text Encode (Prompt)"
      }
    },
    "7": {
      "inputs": {
        "seed": 44444444,
        "steps": 6,
        "cfg": 1.9000000000000001,
        "sampler_name": "lcm",
        "scheduler": "sgm_uniform",
        "denoise": 1,
        "model": [
          "93",
          0
        ],
        "positive": [
          "108",
          0
        ],
        "negative": [
          "108",
          1
        ],
        "latent_image": [
          "56",
          0
        ]
      },
      "class_type": "KSampler",
      "_meta": {
        "title": "KSampler"
      }
    },
    "10": {
      "inputs": {
        "samples": [
          "7",
          0
        ],
        "vae": [
          "1",
          2
        ]
      },
      "class_type": "VAEDecode",
      "_meta": {
        "title": "VAE Decode"
      }
    },
    "53": {
      "inputs": {
        "upscale_method": "nearest-exact",
        "width": 1024,
        "height": 576,
        "crop": "center",
        "image": [
          "100",
          0
        ]
      },
      "class_type": "ImageScale",
      "_meta": {
        "title": "Upscale Image"
      }
    },
    "56": {
      "inputs": {
        "pixels": [
          "53",
          0
        ],
        "vae": [
          "1",
          2
        ]
      },
      "class_type": "VAEEncode",
      "_meta": {
        "title": "VAE Encode"
      }
    },
    "70": {
      "inputs": {
        "control_net_name": "control_v11p_sd15_lineart_fp16.safetensors"
      },
      "class_type": "ControlNetLoaderAdvanced",
      "_meta": {
        "title": "Load Advanced ControlNet Model 🛂🅐🅒🅝"
      }
    },
    "71": {
      "inputs": {
        "coarse": "disable",
        "resolution": 512,
        "image": [
          "53",
          0
        ]
      },
      "class_type": "LineArtPreprocessor",
      "_meta": {
        "title": "Realistic Lineart"
      }
    },
    "72": {
      "inputs": {
        "strength": 0.5,
        "start_percent": 0,
        "end_percent": 0.5,
        "positive": [
          "104",
          0
        ],
        "negative": [
          "6",
          0
        ],
        "control_net": [
          "70",
          0
        ],
        "image": [
          "71",
          0
        ]
      },
      "class_type": "ControlNetApplyAdvanced",
      "_meta": {
        "title": "Apply ControlNet (Advanced)"
      }
    },
    "93": {
      "inputs": {
        "model_name": "mm_sd_v15_v2.ckpt",
        "beta_schedule": "sqrt_linear (AnimateDiff)",
        "motion_scale": 1,
        "apply_v2_models_properly": false,
        "model": [
          "103",
          0
        ],
        "context_options": [
          "94",
          0
        ]
      },
      "class_type": "ADE_AnimateDiffLoaderWithContext",
      "_meta": {
        "title": "AnimateDiff Loader [Legacy] 🎭🅐🅓①"
      }
    },
    "94": {
      "inputs": {
        "context_length": 16,
        "context_stride": 1,
        "context_overlap": 4,
        "context_schedule": "uniform",
        "closed_loop": false,
        "fuse_method": "flat",
        "use_on_equal_length": false,
        "start_percent": 0,
        "guarantee_steps": 1
      },
      "class_type": "ADE_AnimateDiffUniformContextOptions",
      "_meta": {
        "title": "Context Options◆Looped Uniform 🎭🅐🅓"
      }
    },
    "100": {
      "inputs": {
        "video": "simon1.mp4",
        "force_rate": 0,
        "force_size": "Disabled",
        "custom_width": 512,
        "custom_height": 512,
        "frame_load_cap": 0,
        "skip_first_frames": 0,
        "select_every_nth": 2
      },
      "class_type": "VHS_LoadVideo",
      "_meta": {
        "title": "Load Video (Upload) 🎥🅥🅗🅢"
      }
    },
    "101": {
      "inputs": {
        "text": "Anime illustration (Masterpiece, best quality:1.2)"
      },
      "class_type": "ttN text",
      "_meta": {
        "title": "text"
      }
    },
    "102": {
      "inputs": {
        "frame_rate": 12,
        "loop_count": 0,
        "filename_prefix": "AnimateDiff",
        "format": "video/h264-mp4",
        "pix_fmt": "yuv420p",
        "crf": 20,
        "save_metadata": true,
        "pingpong": false,
        "save_output": true,
        "images": [
          "10",
          0
        ]
      },
      "class_type": "VHS_VideoCombine",
      "_meta": {
        "title": "Video Combine 🎥🅥🅗🅢"
      }
    },
    "103": {
      "inputs": {
        "lora_name": "lcm/SD1.5/pytorch_lora_weights.safetensors",
        "strength_model": 1,
        "strength_clip": 1,
        "model": [
          "1",
          0
        ],
        "clip": [
          "1",
          1
        ]
      },
      "class_type": "LoraLoader",
      "_meta": {
        "title": "Load LoRA"
      }
    },
    "104": {
      "inputs": {
        "text": "a beautiful anime illustration   (Masterpiece, best quality:1.2)",
        "clip": [
          "1",
          1
        ]
      },
      "class_type": "CLIPTextEncode",
      "_meta": {
        "title": "CLIP Text Encode (Prompt)"
      }
    },
    "106": {
      "inputs": {
        "resolution": 512,
        "image": [
          "53",
          0
        ]
      },
      "class_type": "Zoe-DepthMapPreprocessor",
      "_meta": {
        "title": "Zoe Depth Map"
      }
    },
    "107": {
      "inputs": {
        "control_net_name": "control_v11f1p_sd15_depth_fp16.safetensors"
      },
      "class_type": "ControlNetLoaderAdvanced",
      "_meta": {
        "title": "Load Advanced ControlNet Model 🛂🅐🅒🅝"
      }
    },
    "108": {
      "inputs": {
        "strength": 0.6,
        "start_percent": 0,
        "end_percent": 0.5,
        "positive": [
          "72",
          0
        ],
        "negative": [
          "72",
          1
        ],
        "control_net": [
          "107",
          0
        ],
        "image": [
          "106",
          0
        ]
      },
      "class_type": "ControlNetApplyAdvanced",
      "_meta": {
        "title": "Apply ControlNet (Advanced)"
      }


    }
  };

  const [comfySettings, setComfySettings] = useState(gifPreset);

  const presetModelMappings = {
    "Anime 1": "cardosAnime_v20.safetensors",
    "Anime 2": "cetusMix_Whalefall2.safetensors",
    "Anime 3": "counterfeitV30_v30.safetensors",
    "Realistic 1": "epicrealism_naturalSinRC1.safetensors",
    "Realistic 2": "realisticVisionV60B1_v51VAE.safetensors",
    "Realistic 3": "photon_v1.safetensors",
  };

  const initialImageState = {
    url: placeholderImage,
    status: 'pending', // 'pending', 'in_progress', 'completed'
    progress: 0, // Progress percentage
  };







  const createImageMetadata = (url, prompt, traits, lora) => ({
    url,
    prompt,
  });

  const handleFileSelect = (event) => {
    setUploadedFiles(event.target.files); // Assuming you have a state to store the selected files
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };


  const handleSaveSettings = () => {
    let settingsData;
    if (backendType === 'automatic') {
      settingsData = {
        prompt: customPrompt,
        negativePrompt: customNegativePrompt,
        settingsJson: settingsJson
      };
    } else { // For ComfyUI
      settingsData = {
        prompt: customPrompt,
        negativePrompt: customNegativePrompt,
        comfySettings: comfySettings,
        selectedModel: selectedModel // Save the selected model
      };
    }

    const blob = new Blob([JSON.stringify(settingsData, null, 2)], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = backendType === 'automatic' ? "settings-automatic.json" : "settings-comfy.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const processSettingsData = (settingsData) => {
    try {
      if (settingsData.settingsJson) {
        // Handle Automatic1111 settings
        setCustomPrompt(settingsData.prompt);
        setCustomNegativePrompt(settingsData.negativePrompt);
        setSettingsJson(settingsData.settingsJson);
        setBackendType('automatic');
      } else if (settingsData.comfySettings) {
        // Handle ComfyUI settings
        setCustomPrompt(settingsData.prompt);
        setCustomNegativePrompt(settingsData.negativePrompt);
        setComfySettings(settingsData.comfySettings);
        setBackendType('comfy');
        setSelectedModel(settingsData.selectedModel);
      } else {
        console.error("Unknown settings format");
      }
    } catch (error) {
      console.error("Error processing settings data:", error);
    }
  };

  const handleLoadSettings = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = (fileLoadedEvent) => {
      const textFromFileLoaded = fileLoadedEvent.target.result;
      processSettingsData(JSON.parse(textFromFileLoaded));
    };

    fileReader.readAsText(event.target.files[0]);
  };

  const handlePresetChange = (event) => {
    const selectedPresetName = event.target.value;
    setSelectedPreset(selectedPresetName);

    if (selectedPresetName) {
      const selectedModelCheckpoint = presetModelMappings[selectedPresetName];
      if (selectedModelCheckpoint) {
        // Create a deep copy of the gifPreset to modify
        let updatedGifPreset = JSON.parse(JSON.stringify(gifPreset));
        updatedGifPreset["1"].inputs.ckpt_name = selectedModelCheckpoint;

        // Update the Comfy settings with the new preset
        setComfySettings(updatedGifPreset);
      }
    }
  };






  // Usage of the input file element
  <input type="file" id="fileInput" className="form-control" onChange={handleLoadSettings} />

  const updateImageFilenameInSettings = (settings, filename) => {
    for (const key in settings) {
      const node = settings[key];
      if (node.class_type === "LoadImage" && node.inputs && node.inputs.image) {
        node.inputs.image = filename;
      }
    }
  }


  const replacePlaceholders = (obj, prompt, negativePrompt) => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/{prompt}/g, prompt).replace(/{negative prompt}/g, negativePrompt);
      } else if (typeof obj[key] === 'object') {
        replacePlaceholders(obj[key], prompt, negativePrompt);
      }
    });
  };

  const replaceRandomTag = (obj) => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/{random}/g, () => Math.floor(Math.random() * 1000000));
      } else if (typeof obj[key] === 'object') {
        replaceRandomTag(obj[key]);
      }
    });
  };

  const replaceModelTag = (obj, selectedModel) => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/{model}/g, selectedModel);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceModelTag(obj[key], selectedModel);
      }
    });
  };


  const parseJSONGracefully = (jsonString, defaultValue = {}) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return defaultValue; // or return an error message/object indicating the issue
    }
  };

  const replaceSeedValuesWithRandom = (obj) => {
    Object.keys(obj).forEach(key => {
      if (key === 'seed' && typeof obj[key] === 'number') {
        obj[key] = Math.floor(Math.random() * 1000000000); // Random seed
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceSeedValuesWithRandom(obj[key]); // Recurse into nested objects
      }
    });
  };

  const [selectedModel, setSelectedModel] = useState("realvisxlV30_v30Bakedvae"); // Default model
  const [modelOptions, setModelOptions] = useState([]);

  const fetchModels = async () => {
    try {
      const payload = {
        input: {
          api: {
            method: "GET",
            endpoint: "/sdapi/v1/sd-models"
          },
          payload: {}
        }
      };

      const response = await axios.post('https://api.runpod.ai/v2/4633x1fjhlagrj/runsync', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`// Make sure myAPIKey is defined
        }
      });

      if (response.data && response.data.output) {
        setModelOptions(response.data.output.map(model => model.model_name));
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  useEffect(() => {
    fetchModels(); // Fetch models on component mount
  }, []);


  /*
    useEffect(() => {
      const fetchPresets = async () => {
        try {
          const response = await axios.get(`${apiURL}/api/presets`);
          if (response.data && response.data.presets) {
            setPresetOptions(response.data.presets);
          }
        } catch (error) {
          console.error('Error fetching presets:', error);
        }
      };
  
      fetchPresets();
    }, []);
  
  */

  const [numberOfImages, setNumberOfImages] = useState(1);

  const [customPrompt, setCustomPrompt] = useState('a beautiful anime illustration (Masterpiece, best quality:1.2)');
  const [customNegativePrompt, setCustomNegativePrompt] = useState('(worst quality, low quality, deformed face, deformed eyes, nude)');

  const [settingsJson, setSettingsJson] = useState(`{
      "override_settings": {
        "sd_model_checkpoint": "realvisxlV30_v30Bakedvae"
      },
      "override_settings_restore_afterwards": true,
      "seed": -1,
      "batch_size": 1,
      "steps": 30,
      "cfg_scale": 7,
      "hr_scale": 2,
      "enable_hr": false,
      "hr_second_pass_steps": 20,
      "hr_upscaler": "Latent",
      "denoising_strength": 0.4,
      "width": 1536,
      "height": 1024,
      "sampler_name": "DPM++ 2M SDE Karras",
      "sampler_index": "DPM++ 2M SDE Karras",
      "restore_faces": false
    }`);


  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  useEffect(() => {
    // When the component mounts, you can set additional presets if required
    // For now, it will just use the default preset defined above
    setComfySettings(gifPreset);
  }, []);

  const jobTimers = useRef({});


  // CHECK JOB FUNCTION ************************



  const checkJobStatus = async (job) => {
    try {
      const endpoint = `https://api.runpod.ai/v2/krlmownqiu9g79/status/${job.id}`;
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`
        },
      });

      console.log(`Received status update for job ID ${job.id}: `, response.data);
      console.log("Is loading:", isLoading); // Add this to see the state update

      // Update status
      setApiStatus(response.data.status);

      if (response.data.status === 'IN_PROGRESS') {
        if (!jobTimers.current[job.id]) {
          console.log(`Starting timer for job ID: ${response.data.id}`);
          const estimatedSeconds = videoDuration * 52 + 20; // Adjust based on actual needs
          setCountdown(estimatedSeconds);

          jobTimers.current[job.id] = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown > 0) return prevCountdown - 1;
              clearInterval(jobTimers.current[job.id]);
              return 0;
            });
          }, 1000);
        }
      } else {
        // Clear interval if job status is not 'IN_PROGRESS'
        if (jobTimers.current[job.id]) {
          clearInterval(jobTimers.current[job.id]);
          delete jobTimers.current[job.id];
        }
      }

      // If the job is completed, process the output
      if (response.data.status === 'COMPLETED' && response.data.output && response.data.output.images) {
        const videoOutput = response.data.output.images[0];
        if (videoOutput && videoOutput.url) {
          setGeneratedVideoUrl(videoOutput.url); // Update state with the video URL
          console.log("Video URL received and set:", videoOutput.url);
        }
      }

      // Handle completed or failed job
      if (['COMPLETED', 'FAILED'].includes(response.data.status)) {
        clearInterval(jobTimers.current[job.id]);
        delete jobTimers.current[job.id];
        setIsLoading(false);  // Set isLoading to false when job is completed or failed
      }

      return response.data.status === 'COMPLETED' ? job.id : null;
    } catch (err) {
      console.error("Error checking job status:", err);
      clearInterval(jobTimers.current[job.id]);
      delete jobTimers.current[job.id];
      setIsLoading(false);  // Ensure isLoading is false on error
      return null; // Return null in case of error
    }
  };




  // GENERATE ART FUNCTION ************************

  // GENERATE ART FUNCTION ************************

  const generateArt = async () => {
    setIsLoading(true);
    isGeneratingImage.current = true;
    setGeneratedVideoUrl(''); // Reset the generated video URL at the start

    // First, move current images to the gallery
    setGalleryImages(prevGallery => [...prevGallery, ...currentImages.map(image => ({ ...image }))]);
    setCurrentImages([]); // Optionally reset current images

    // Reset placeholders with initial status and progress
    const placeholders = new Array(numberOfImages).fill().map((_, idx) => ({
      url: placeholderImage,
      metadata: null,
      progress: 0,
      status: 'waiting', // Start with 'waiting' status
    }));

    setCurrentImages(placeholders);

    // Initialize arrays for new job IDs and mapping of job IDs to image indices
    const newJobIds = [];
    const newJobIndexMap = {}; // Initialize as an empty object

    let response; // Declare here for broader scope

    // If no video URL is available, return early
    if (!presignedDownloadUrl) {
      console.error("Video URL is required for the payload");
      setIsLoading(false);
      isGeneratingImage.current = false;
      return;
    }

    //console.log("Sending request to ComfyUI API", comfySettings);
    //require('dotenv').config(); 

    //console.log("AWS Secret Key:", process.env.REACT_APP_AWS_SECRET_ACCESS_KEY);

    let comfyPayload = {
      input: {
        handler: "RawWorkflow",
        aws_access_key_id: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        aws_secret_access_key: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        aws_endpoint_url: "https://runpod-store2.s3.us-east-1.amazonaws.com",
        aws_bucket_name: process.env.REACT_APP_AWS_BUCKET_NAME,
        webhook_url: process.env.REACT_APP_WEBHOOK_URL,
        workflow_json: JSON.parse(JSON.stringify(comfySettings)),
        policy: {
          executionTimeout: 900000,
        }
      }
    };

    comfyPayload.input.workflow_json['100'].inputs.video = presignedDownloadUrl; // Update video URL dynamically
    comfyPayload.input.workflow_json['104'].inputs.text = customPrompt; // Update custom prompt

    //console.log("Sending request to ComfyUI API", comfyPayload);

    try {
      response = await axios.post(`https://api.runpod.ai/v2/krlmownqiu9g79/run`, comfyPayload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`
        },
      });
      console.log("Received response from ComfyUI API", response.data);

      if (response.data && response.data.id) {
        newJobIds.push({ id: response.data.id, backendType: 'comfy' });
        newJobIndexMap[response.data.id] = { index: 0 }; // Assuming single video generation at a time
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    } finally {

      isGeneratingImage.current = false;
      setJobIds(prevJobIds => [...prevJobIds, ...newJobIds]);
      setNewJobIndexMap(prevMap => ({ ...prevMap, ...newJobIndexMap }));
      console.log("newJobIndexMap after generation:", newJobIndexMap);
    }
  }

  // Update Prgress and Timer

  useEffect(() => {
    const interval = setInterval(async () => {
      const completedJobIds = await Promise.all(
        jobIds.map(async (job) => await checkJobStatus(job))
      );

      // Filter out nulls and update the jobIds state to remove completed jobs
      const idsToRemove = completedJobIds.filter(id => id !== null);
      if (idsToRemove.length > 0) {
        setJobIds(prevJobIds => prevJobIds.filter(job => !idsToRemove.includes(job.id)));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [jobIds]); // Depend on jobIds

  useEffect(() => {
    let timer = null;
    if (countdown > 0 && apiStatus === 'IN_PROGRESS') {
      timer = setInterval(() => {
        setCountdown((prev) => {
          const nextTick = prev - 1;
          if (nextTick <= 0) {
            clearInterval(timer);
            return 0;
          }
          return nextTick;
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup on unmount or status change
  }, [countdown, apiStatus]);





  // ACTUAL PAGE ************************

  return (

    <div className="container my-5">
      <w3m-button />
      <h1 className="text-center display-4 mb-4">Video 2 Video Generator</h1>
      {isConnected && hasAccess ? (
        // If the wallet is connected, display the main content
        <div className="d-flex flex-column align-items-center">


          {/* Button to initiate video generation */}
          <div className="mb-4">
            <button
              onClick={generateArt}
              className={`btn btn-lg ${isLoading ? 'btn-secondary' : 'btn-dark'}`}
              disabled={isLoading} // Disable the button when isLoading is true
            >
              Generate
            </button>
          </div>

          {/* Video preview section */}
          {generatedVideoUrl && (
            <div className="video-preview-container mb-3" style={{ maxWidth: "600px", width: "100%" }}>
              <video controls style={{ width: "100%", height: "auto" }}>
                <source src={generatedVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* Download button */}
              <a href={generatedVideoUrl} download className="btn btn-primary mt-2">
                Download Video
              </a>
            </div>
          )}

          {/* API status */}
          {apiStatus && (
            <p className="text-center mb-3">{`Status: ${apiStatus}`}</p>
          )}
          {apiStatus === 'IN_PROGRESS' && countdown > 0 && (
            <p>{`Estimated time until completion: ${Math.floor(countdown)} seconds`}</p>
          )}


          {/* Settings and video upload section */}
          <div className="settings-and-upload mb-3 align-items-center" style={{ width: "100%", maxWidth: "600px" }}>
            <div className="form-group mb-3">
              <div className="mb-3">
                <label htmlFor="customPrompt" className="form-label">Custom Prompt</label>
                <textarea
                  id="customPrompt"
                  className="form-control"
                  placeholder="Enter custom prompt"
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  rows="3"
                ></textarea>
              </div>
              <label htmlFor="videoUpload" className="form-label"></label>
              <input
                type="file"
                id="videoUpload"
                className="form-control"
                accept="video/mp4,video/quicktime" // Accept MP4 and MOV files
                onChange={handleFileChange}
              />
              <button onClick={uploadVideo} className="btn btn-primary mt-2">Upload Video</button>
              {/* Upload status and API status */}
              {uploadStatus && (
                <p className="text-center mb-3">{uploadStatus}</p>
              )}
            </div>

            {/* Video preview after upload */}
            {videoPreviewUrl && (
              <div className="video-preview-container" style={{ width: "100%", textAlign: "center" }}>
                <video controls style={{ width: "100%", height: "auto" }}>
                  <source src={videoPreviewUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}

            {/* Preset selection */}

            <form>
              <div className="mb-3">
                <label htmlFor="presetSelect" className="form-label">Select a preset</label>
                <select
                  id="presetSelect"
                  className="form-select"
                  value={selectedPreset}
                  onChange={handlePresetChange}
                >
                  <option value="">Select a preset</option>
                  <option value="Anime 1">Anime 1 - Cardos</option>
                  <option value="Anime 2">Anime 2 - Cetus</option>
                  <option value="Anime 3">Anime 3 - Counterfeit</option>
                  <option value="Realistic 1">Realistic 1 - EpicRealism</option>
                  <option value="Realistic 2">Realistic 2 - RealisticVision</option>
                  <option value="Realistic 3">Realistic 3 - Photon</option>
                </select>
              </div>
            </form>
            <p className="text  -center mb-3">Welcome to the experimental beta of my video to video generator. This uses ComfyUI on Runpod Serverless as a backend. It takes around 1 minute to generate 1 second of video. Limited to 20MB and 20 seconds or 20 minutes of rendering. Recommended model is Cardos but feel free to try the others. Estimated time is really an estimate. Don't refresh the page until generation finishes or fails. Have fun!</p>
          </div>
        </div>
      ) : (
        // If the wallet is not connected, display a message
        <h2 className="text-center display-6 mb-3">
          Please connect your wallet. To access the app you need to hold either an electric.film Genesis NFT or 5000 $ELECTRIC tokens
        </h2>
      )}
    </div>

  );

}

// Wrap your App component with Web3ModalProvider (or directly with WagmiProvider if you don't need extra setup)
export default function RootApp() {
  return (
    <WagmiProvider config={config}>
      <App />
    </WagmiProvider>
  );
}